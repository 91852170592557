import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { colors } from '../styles/theme';
import { getBuild } from '../utils/api';
import { toggleNav, updateBuild } from '../store/build';
import { useDispatch } from 'react-redux';
import { Link } from 'gatsby';
import SEO from '../components/SEO';

const PaymentPage = ({ location }) => {
  const [isSuccessful, setSuccessful] = useState(null);
  const [reservationId, setReservationId] = useState(null);
  const [buildId, setBuildId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      setSuccessful(params.get('result') === 'success');
      setBuildId(params.get('buildId'));
      setReservationId(params.get('reservationId'));
      if (params.get('buildId').length === 12) {
        // link contain build id, restore build
        getBuild(params.get('buildId')).then(build => {
          dispatch(updateBuild(build));
          dispatch(toggleNav(false));
        });
      }
    }
  }, []);

  return (
    <>
      <SEO title={isSuccessful ? 'Payment Succeed' : 'Payment Canceled'} />
      <Layout>
        {isSuccessful === true ? (
          <Container>
            <h1>Thank you for placing a reservation with us.</h1>
            <p>
              Your Reservation ID is: {reservationId}. A Neu team member will be
              in touch soon.
            </p>
            <p>
              Feel free to share your build using this{' '}
              <a
                href={`https://neucommunity.com/builder/?src=build&id=${buildId}`}
              >
                link
              </a>
              :
            </p>
            <a
              href={`https://neucommunity.com/builder/?src=build&id=${buildId}`}
            >
              https://neucommunity.com/builder/?src=build&id={buildId}
            </a>
          </Container>
        ) : isSuccessful === false ? (
          <Container>
            <h1>Payment was canceled</h1>
            <p>
              We saved your build with this{' '}
              <a
                href={`https://neucommunity.com/builder/?src=build&id=${buildId}`}
              >
                link
              </a>
              :
            </p>
            <a
              href={`https://neucommunity.com/builder/?src=build&id=${buildId}`}
            >
              https://neucommunity.com/builder/?src=build&id={buildId}
            </a>
            <p>
              Feel free to go to the <Link to='/builder/'>builder</Link> and try
              again
            </p>
          </Container>
        ) : (
          <Container></Container>
        )}
      </Layout>
    </>
  );
};
const Container = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 3rem;
  row-gap: 1.5rem;

  a {
    color: ${colors.blue};
  }
`;

export default PaymentPage;
